.top-bar-menu-container {
  background-color: #373737;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  z-index: 100;

  @media (min-width: 992px) {
    left: 200px;
  }
}

.masthead-top-bar-menu {
  position: relative;

  & + .ant-layout {
    padding-top: 100px;
  }
}

@media (max-width: 991.98px) {
  .top-bar-menu-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0,0,0,.3);
    display: none;
    z-index: 99;

    &.isActive {
      display: block;
    }
  }
}

.top-bar-menu-overlay {
  display: none;
}


.top-bar-menu-item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 40px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    color: darken($wht, 30%);
    background-color: #504f4f;
    -webkit-transition: background-color 500ms ease-in;
    -ms-transition: background-color 500ms ease-in;
    transition: background-color 500ms ease-in;
  }
}

.selected-top-bar {
  background-color: #aaa;
}