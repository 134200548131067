.vp-user-header {
  margin-left: 15px;
  font-weight: 700;
  font-size: 1.2rem;
  color: rgb(254, 96, 44);
  margin-bottom: 55px
}

.info-panel-head {
  display: flex;
  background-color: rgb(254, 96, 44);
  color: #fff;
  width: 100%;
  height: 2.2rem;
}

.info-panel-head-text {
  margin: auto 10px;
  font-size: 1rem;
  font-weight: 500;
}

.label-input {
  // justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 25px;
  margin-left: 15px;
  width: 175px;
}

.text-input {
  border-radius: 4px;
  margin: 4px 0;
  border: 1px solid #d4d4d4;
  padding: 4px 12px;
  height: 35px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: orange !important;
  border-color: orange !important;
}

.ant-checkbox-checked::after {
  border: 1px solid darken($color: orange, $amount: 5);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: orange !important;
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.ant-checkbox-inner::after {
  top: 45%;
  width: 6px;
  height: 12px;
}

.info-panel-content-container {
  margin-bottom: 25px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.row-container {
  display: flex;
  margin-bottom: 5px;
  padding-right: 5px;

  & .noAvansDiv {
    display: flex;
    align-items: center;
    gap: 25px;
  }
}

.sync-button {
  >button {
    background-color: #f84804;
  }
}