.noBackgroundColor{
  background-color: transparent;
}
.greenBackgroundColor{
 background-color: rgb(223, 240, 216) !important;
}
.blueBackgroundColor{
  background-color: rgb(217, 237, 247) !important;
}
.yellowBackgroundColor{
  background-color: rgb(252, 248, 227) !important;
}
.redBackgroundColor{
  background-color: rgb(242, 222, 222) !important;
}

.orders-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  color: $k70;

  &__left {
    flex: 1;
    background: #dfdfdf;
    border: 1px solid $k10;
    display: flex;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 40px;
    gap: 40px;
    padding: 30px;
  }

  .order-info-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: auto;
  }
  .order-info-right-bottom{
    display: flex;
    justify-content: space-between;
    gap:20px;
    border-top: 1px solid lightgray;
    padding-top: 24px;
    
    @media screen and (max-width:1400px) {
      flex-wrap: wrap;
    }
  }

  &__right {
    width: 400px;
    margin-left: 20px;
    background: #dfdfdf;
    border: 1px solid $k10;
    padding: 50px;
  }

  .buyer-row {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &__left {
      width: 100px;
      margin-right: 10px;
    }

    &__right {
      flex: 1;
    }
  }
}

.order-references {
  display: flex;

  .ant-form-item {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.buyer-row--isporuka {
  color: $hmtxOrange;
  font-weight: 600;
  border-bottom: 1px solid lightgray;
}

.references-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-info {
  .order-info--top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    .order-time {
      margin-bottom: 5px;
    }

    .ant-btn {
      background-color: $k25;
      box-shadow: none;
      border-radius: 5px;
      max-width: 12rem;
      min-width: 130px;
    }
  }

  .order-info--bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    .order-status {
      margin-bottom: 10px;
    }
  }
}

.orders-container__right {
  .indRow {
    display: flex;
    border-bottom: 1px solid $k20;
    padding: 0 10px 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &--left {
      flex: 1;
    }

    &--right {
      width: fit-content;
      min-width: 80px;
    }
  }
}

.hmtxOrange {
  color: $hmtxOrange;
  text-transform: uppercase;
  font-weight: 600;
}

.view-orders {
  .ant-table {
    color: $k70;
  }

  .icon-label {
    color: $k70;
  }

  .ant-row {
    justify-content: flex-start;
    margin: 0 !important;
  }

  .ant-col {
    padding: 0 !important;
  }

  .dark-grey-button {
    margin-top: 30px;
    background-color: $k70;
    border-radius: 5px;
    height: 40px;
    font-size: 10px;
  }

  .order-references .ant-form-item {
    margin-bottom: 5px !important;
  }
}