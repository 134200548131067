.actions-block {
  padding: 0 15px 5px;
  // margin-bottom: 20px;
  //margin-bottom: 8px;
  background-color: $background-color;
  // border-radius: 2px;
}

.users-table {
  .actions-block {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  opacity: 0;
}

.forgot-password-span {
  font-size: 16px;
  color: transparent;
  margin-left: 210px;
  cursor: pointer;

  @media (max-width: 415px) {
    margin-left: unset;
  }
}

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-content h2 {
  color: #c2c2c2 !important;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 18px;
}

@media screen and(max-width:992px) {
  .login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 12px;
    margin: 0px 12px;
    border: 1px solid #c2c2c2 !important;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .ant-form-item-control-input-content .ant-btn-primary {
    margin-right: 12px;
  }

  .forgot-password-span {
    font-size: 12px;
  }
}

@media screen and(min-width:768px) and (max-width:992px) {
  .loginFirstCol {
    max-width: 100%;
    flex: auto;
  }

  .loginSecCol {
    display: none;
  }
}

.Col {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-image-img {
  width: 2500px;
  height: 100vh;
}

.ant-input,
.ant-input-affix-wrapper {
  border: none;
  border-bottom: 1px solid #c2c2c2;
  font-size: 14px !important;
  padding-left: 0px !important;
}

.orangeInput {
  padding-left: 5px !important;
}

@media screen and (max-width:768px) {
  .login-image {
    display: none !important;
  }
}

.ant-input::placeholder {
  color: #F95E00;
}