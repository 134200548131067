.contact-item {

  .ant-form-item-label {
    text-align: left;
    min-width: 150px;
  }
}

.contact-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.contact-table {

  .ant-table-thead>tr {
    >.ant-table-cell {
      text-transform: uppercase;
    }
  }

  .ant-table-tbody>tr {
    >:nth-child(3) {
      text-transform: uppercase;
    }
  }
}