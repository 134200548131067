th,
td {
  font-size: 0.875em;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    padding: 13px 15px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ffffff;
  }

  >thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
  }

  >caption+thead>tr:first-child>th,
  >colgroup+thead>tr:first-child>th,
  >thead:first-child>tr:first-child>th,
  >caption+thead>tr:first-child>td,
  >colgroup+thead>tr:first-child>td,
  >thead:first-child>tr:first-child>td {
    border-top: 0;
  }

  >tbody {
    +tbody {
      border-top: 2px solid #ffffff;
    }

    tr {
      &:hover {
        >td {
          background-color: #f0f6f8;
        }
      }
    }
  }
}

.table>thead>tr:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.table>thead>tr:first-child>th:first-child {
  border-top-left-radius: 2px;
}

.table>thead>tr:first-child>th:last-child {
  border-top-right-radius: 2px;
}

.table>tfoot>tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.table>tfoot>tr:last-child>th:first-child {
  border-bottom-left-radius: 2px;
}

.table>tfoot>tr:last-child>th:last-child {
  border-bottom-right-radius: 2px;
}

.table>tbody>tr:last-child>th:first-child,
.table>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 2px;
}

.table>tbody>tr:last-child>th:last-child,
.table>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 2px;
}

.table>tfoot+tbody>tr:last-child>th:first-child,
.table>tfoot+tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 0;
}

.table>tfoot+tbody>tr:last-child>th:last-child,
.table>tfoot+tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 0;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ffffff;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    border: 1px solid #ffffff;
  }

  >thead>tr>th,
  >thead>tr>td {
    border-bottom-width: 2px;
  }
}



.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #fcfcfd;
}

.table-hover>tbody>tr:hover {
  background-color: #e7e9ee;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0.01%;

  @include media('<md') {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ffffff;
  }

  th,
  td {
    @include media('<md') {
      white-space: nowrap;
    }
  }

  >.table-bordered {
    @include media('<md') {
      border: 0;

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* colors */
.table>.success>tr>th,
.table>.success>tr>td {
  background-color: #505b72;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

// .table>.primary>tr>th,
// .table>.primary>tr>td {
//   background-color: #2574ab;
//   color: #fff;
//   border-color: rgba(255, 255, 255, 0.25);
// }

.ant-table {
  table {
    tbody>tr {
      background-color: whitesmoke;
    }

    tbody>tr:nth-child(2n + 1) {
      background-color: #ebebeb;
    }
  }

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    padding: 4px 15px !important;
  }
}

.table>.success-2>tr>th,
.table>.success-2>tr>td {
  background-color: #4f66a0;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.danger>tr>th,
.table>.danger>tr>td {
  background-color: #d9534f;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.success>tr:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.table>.success>tr:first-child>th:first-child,
.table>.success>tr:first-child>td:first-child {
  border-top-left-radius: 0;
}

.table>.success>tr:first-child>th:last-child,
.table>.success>tr:first-child>td:last-child {
  border-top-right-radius: 0;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;

  a {
    display: inline-flex;
    width: 20px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    vertical-align: baseline;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //color: #4f66a0;
  color: #000000;
}

.ant-tabs-tab:hover {
  color: #4f66a0;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #4f66a0;
}

.ant-tabs-ink-bar {
  background: #4f66a0;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #4f66a0;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4f66a0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar {
  background: #4f66a0;
}

td.ant-table-column-sort {
  background: initial;
}

.iconLabelContainer {
  position: sticky;
  top: 100px;
  left: 0;
  z-index: 66;
  background-color: white;
  padding: 10px;
}

.actions-block {
  background-color: $wht !important;
  min-height: 80px;
  border-radius: $table-border-radius-top;
  //padding-top: 10px ;
  display: flex;
  align-items: center;
}

.table {
  // border-radius: $table-border-radius;
  // box-shadow: $table-box-shadow;
  overflow: scroll !important;
  // .actions-block {
  //   margin-bottom: 8px;
  // }
}

.ant-table-filter-dropdown {
  padding: 10px;
  border-radius: 4px;

  // .ant-btn {
  //   background: linear-gradient(180deg, #3372ed 0%, #1138c2 100%);
  //   border-radius: 5px;
  //   font-weight: 600;

  //   width: $small-btn-width !important;
  //   span {
  //     color: #fff;
  //   }
  // }
}

//th.ant-table-cell{
//  padding: 20px 10px !important;
//}

.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
  opacity: 0.8 !important;
  color: #000 !important;
}

.lock:hover .icon-lock {
  display: inline;
}

.lock:hover svg {
  // color: #1138c2 !important;
}

.view-orders {
  .ant-table {
    p {
      margin: 0;
      padding: 0;
    }
  }
}

.refresh-icon>button {
  background: unset !important;
  border: 1px solid grey !important;
  height: auto !important;
  border-radius: 5px !important;
  padding: 4px 6px !important;
}

.refresh-icon>button>span {
  height: 16px;
  width: 16px;
}

.user-table {
  .ant-table {
    border-top: unset !important;

    .ant-table-tbody>tr>td {
      padding: 3px 3px !important;
    }
  }

  .ant-table-thead>tr>th {
    background-color: whitesmoke;
    padding: 3px !important;
    color: #444;
    font-size: 10px;
    font-weight: bold;
    font-family: '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'Noto Sans',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji' !important;
    border: unset !important;
    border-top: unset !important;
    border-bottom: 1px solid #888 !important;

    &.ant-table-column-has-actions {
      &.ant-table-column-has-sorters:hover {
        background-clip: padding-box;
        background-color: darken($c-primary, 3);
      }

      &.ant-table-column-has-filters {
        &:hover {

          .ant-table-filter-icon:hover,
          .anticon-filter:hover {
            background-color: darken($c-primary, 3);
            color: #fff;
          }
        }

        .anticon-filter {
          &.ant-table-filter-open {
            background-color: darken($c-primary, 3);
            color: #fff;
          }
        }

        .ant-table-filter-icon.ant-table-filter-open {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }
    }

    &.ant-table-column-sort {
      background-color: darken($c-primary, 3);
    }

    .ant-table-column-sorter {
      color: #fff;

      &-down.on,
      &-up.on {
        color: yellow;
      }
    }

    .ant-table-filter-selected.anticon-filter {
      color: yellow;
    }
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}