.h-100 {
  height: 100%;
}

.mh-100 {
  min-height: 100%;
}

.pt-0 {
  padding-top: 0 !important;
}

.inline-flex {
  display: flex;
  justify-content: space-between;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}